@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700&family=Open+Sans:wght@400;700&display=swap');

* {
  box-sizing: border-box;
}

body {
  font-family: 'Open Sans', 'Lato', sans-serif;
}

@media (max-width: 1200px) {
  html,
  body {
    font-size: 100%;
  }
}

@media (max-width: 992px) {
  html,
  body {
    font-size: 93.75%;
  }
}

@media (max-width: 768px) {
  html,
  body {
    font-size: 87.5%;
  }
}

@media (max-width: 576px) {
  html,
  body {
    font-size: 81.25%;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Lato', sans-serif;
  font-weight: 700;
}

.ant-spin-dot-item {
  background-color: #65c8c6;
}

.ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}

/* width */
::-webkit-scrollbar {
  width: 1.5rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: #504f4f;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #454545;
  border: 4px #504f4f solid;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #3c3c3c;
  opacity: 0.8;
}

.root {
  width: 100%;
  height: 100vh !important;
}

.login-background {
  flex: 1;
  background-color: #454545;
}

.service-type-container {
  width: auto;
  min-width: 200px;
  display: inline-grid;
  justify-content: center;
  padding: 11px 0px;
  background: rgba(255, 255, 255, 0.7);
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  border-radius: 30px;
  margin-top: 10%;
}

.signup-bar {
  width: auto;
  min-width: 200px;
  display: inline-grid;
  justify-content: center;
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 29%);
  border-radius: 20px;
  margin-top: 5%;
}

.primary-button {
  background-color: #1a3c6e;
  border: 0.5px solid #1a3c6e;
  color: #fff !important;
  border-radius: 20px;
  box-shadow: 0px 3px 6px rgba(26, 60, 110, 0.5);
}

.primary-button:hover {
  background-color: #fff !important;
  color: #1a3c6e !important;
  border-color: #1a3c6e;
  box-shadow: 0px 3px 6px rgba(26, 60, 110, 0.5);
}

/* login modal */
/* .login-form button {
  color: #86858f;
} */

/* .login-form button:hover, .login-form button:focus {
  color: #fff;
  background-color: #1a3c6e;
} */

.login-form input {
  border-width: 1px;
  border-color: #000;
  border-radius: 10px;
}

.login-form input:hover,
.login-form input:focus {
  border-color: #1a3c6e;
  box-shadow: none;
}

.search input {
  background-color: #65c8c6;
  color: white;
  height: 38px;
  margin-top: 0;
}

.search input::placeholder {
  color: white;
}

.content-body span {
  color: white !important;
  font-size: 14px !important;
  font-family: 'Open Sans', 'Lato', sans-serif !important;
  background-color: transparent !important;
}

.content-body font {
  color: white !important;
  font-size: 14px !important;
  font-family: 'Open Sans', 'Lato', sans-serif !important;
}

.content-body p {
  color: white !important;
  font-size: 14px !important;
  font-family: 'Open Sans', 'Lato', sans-serif !important;
  margin-bottom: 0.65rem;
}


.content-body::-webkit-scrollbar {
  display: none;
}

.content-body li {
  color: white !important;
  font-family: 'Open Sans', 'Lato', sans-serif !important;
  font-size: 14px !important;
  margin-bottom: 0.65rem;
}

.content-body tb {
  color: white !important;
  margin-bottom: 0.65rem;
  font-size: 14px !important;
  font-family: 'Open Sans', 'Lato', sans-serif !important;
}

.content-body b {
  color: white !important;
  margin-bottom: 0.65rem;
  font-size: 14px !important;
  font-family: 'Open Sans', 'Lato', sans-serif !important;
}

.content-body h1 {
  font-size: 2.25rem;
  margin-bottom: 0.65625rem;
  color: white !important;
  font-family: 'Open Sans', 'Lato', sans-serif !important;
}

.content-body h2 {
  font-size: 1.5rem;
  margin-bottom: 0.65625rem;
  color: white !important;
  font-family: 'Open Sans', 'Lato', sans-serif !important;
}

.content-body h3 {
  font-size: 1.25rem;
  margin-bottom: 0.65625rem;
  color: white !important;
  font-family: 'Open Sans', 'Lato', sans-serif !important;
}

.content-body h4 {
  font-size: 1.125rem;
  margin-bottom: 0.65625rem;
  color: white !important;
  font-family: 'Open Sans', 'Lato', sans-serif !important;
}

.content-body h5 {
  font-size: 1rem;
  margin-bottom: 0.65625rem;
  color: white !important;
  font-family: 'Open Sans', 'Lato', sans-serif !important;
}

.content-body p {
  margin-bottom: 0.65rem;
}

.h1Indent {
  padding-left: 0px;
}

.h2Indent {
  padding-left: 10px;
}

.h3Indent {
  padding-left: 20px;
}

.loading-screen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0);
  z-index: 999999;
  align-items: center;
  justify-content: center;
  display: flex;
}

.ant-modal-content {
  border-radius: 30px;
}

.ant-btn {
  height: 40px;
}

.ant-input {
  height: 40px;
}

.ant-input-password {
  padding: 0 12px;
}

.table-responsive {
  color: white !important;
  width: auto !important;
  font-family: 'Open Sans', 'Lato', sans-serif !important;
}

.table-bordered {
  color: white !important;
  border-color: white !important;
}

.table-responsive td {
  border: 0.5px solid white;
  font-size: 14px !important;
  font-family: 'Open Sans', 'Lato', sans-serif !important;
}

.content-body a {
  background-color: transparent !important;
  color: #65c8c6 !important;
}

.table-responsive li {
  padding-left: 5px;
  font-size: 14px !important;
  font-family: 'Open Sans', 'Lato', sans-serif !important;
  display: list-item !important;
}

ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

li {
  display: list-item;
  text-align: -webkit-match-parent;
}

ol {
  list-style-type: decimal;
}

ul ul {
  list-style-type: circle;
}

ul ul ul {
  list-style-type: square;
}
